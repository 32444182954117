import React, { useState } from 'react'
import Img from 'gatsby-image'

const HexagonsComponent = props => {
  const [isFlipped, setIsFlipped] = useState(props.customer.flipped)

  const toggleFlip = bool => {
    if (bool) {
      if (props.customer.text) {
        setIsFlipped(true)
      }
    } else {
      setIsFlipped(false)
    }
  }

  const goToLink = () => {
    const url = props.customer.link
    if (props.customer.text) {
      if (isFlipped && typeof window !== 'undefined') {
        window.open(url, '_blank')
      } else {
        toggleFlip(true)
      }
      return
    }
    if (typeof window !== 'undefined') {
      window.open(url, '_blank')
    }
  }

  return (
    <li
      className="hex"
      onMouseEnter={() => toggleFlip(true)}
      onMouseLeave={() => toggleFlip(false)}
      onClick={() => toggleFlip(!isFlipped)}
    >
      <div className="hexIn">
        <div className="hexLink">
          {props.customer.text ? (
            <div
              className="img"
              style={{
                backgroundImage: `url(${props.customer.logoSmall.file.url})`,
                backgroundSize: props.backgroundSize,
                backgroundColor: props.backgroundColor || '#eee',
                opacity: isFlipped ? 0 : 1,
                cursor: 'pointer',
              }}
            />
          ) : (
            <div
              className="img"
              onClick={() => goToLink()}
              style={{
                backgroundImage: `url(${props.customer.logoSmall.file.url})`,
                backgroundColor: props.backgroundColor || '#eee',
                backgroundSize: props.backgroundSize,
                opacity: isFlipped ? 0 : 1,
                cursor: 'pointer',
              }}
            />
          )}
          {isFlipped && props.customer.text && (
            <div className="customer-testimonial">
              <div className="customer-image">
                <a
                  href={props.customer.link}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="hexLink"
                >
                  <div className="customer-link">
                    <Img fluid={props.image.fluid} />
                  </div>
                </a>
              </div>
              <div onClick={() => goToLink()} style={{ cursor: 'pointer' }}>
                «{props.customer.text.json.content[0].content[0].value}»
                {props.customer.name && (
                  <span className="customer-name" onClick={() => goToLink()}>
                    {props.customer.name}
                  </span>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </li>
  )
}

export default HexagonsComponent
